<template>
  <div class="teacherManage">
    <div class="button-box">
      <el-button type="success" icon="el-icon-plus" @click="addTeacher"
        >新增成员</el-button
      >
      <el-button type="primary" icon="el-icon-refresh" @click="resetAllTeacher"
        >初始全校教师密码</el-button
      >
      <el-button
        type="primary"
        @click="downLoadTeacherTemplate"
        icon="el-icon-document"
        >下载教师模板</el-button
      >
      <el-upload
        action="/api/media/importManager.do"
        :headers="uploadHeader"
        name="uploadFile"
        style="margin: 0 10px"
        :on-success="uploadSuccess"
        :show-file-list="false"
      >
        <el-button type="primary" icon="el-icon-upload2">导 入</el-button>
      </el-upload>
      <el-button type="primary" @click="exportFile(1)" icon="el-icon-download"
        >导 出</el-button
      >
      <el-button type="danger" @click="batchDelete" icon="el-icon-delete"
        >批量删除</el-button
      >
      <el-button type="primary" @click="exportFile(2)" icon="el-icon-download"
        >导出教师头像</el-button
      >
    </div>
    <div class="flex-box">
      <div></div>
      <el-form :inline="true" :model="queryTeacherForm">
        <el-form-item prop="telephone">
          <el-input
            v-model="queryTeacherForm.telephone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="name">
          <el-input
            v-model="queryTeacherForm.name"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item prop="role">
          <el-select
            v-model="queryTeacherForm.role"
            placeholder="请选择角色"
            filterable
            value-key="id"
          >
            <el-option
              v-for="role in roleList"
              :key="role.id"
              :label="role.name"
              :value="{ name: role.name, id: role.id }"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="state">
          <el-select v-model="queryTeacherForm.state" placeholder="在职">
            <el-option
              :label="state.name"
              v-for="state in stateList"
              :key="state.value"
              :value="state.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="identity">
          <el-select
            v-model="queryTeacherForm.identity"
            clearable
            placeholder="请选择身份"
          >
            <el-option
              :label="identity.name"
              v-for="identity in identityList"
              :key="identity.value"
              :value="identity.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="loadAllTeacherList(1)"
            >查 询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="teacherTable" @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        align="center"
        width="55px"
      ></el-table-column>
      <el-table-column
        prop="name"
        align="center"
        label="用户名"
      ></el-table-column>
      <el-table-column
        prop="group_name"
        align="center"
        label="所属部门"
      ></el-table-column>
      <el-table-column
        prop="rolename"
        align="center"
        label="角色"
      ></el-table-column>
      <el-table-column
        prop="mobile"
        align="center"
        label="手机号"
      ></el-table-column>
      <el-table-column
        prop="minister"
        align="center"
        label="负责部门"
      ></el-table-column>
      <el-table-column prop="sex" align="center" label="性别"></el-table-column>
      <el-table-column
        prop="state"
        align="center"
        label="状态"
      ></el-table-column>
      <el-table-column
        prop="opername"
        align="center"
        label="操作人"
      ></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="warning" @click="details(scope.row)"
            >详 情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev,pager,next"
      :page-count="page.pageCount"
      :page-size="page.currentPage"
      @current-change="loadAllTeacherList"
    ></el-pagination>
    <el-dialog title="新增用户" :visible.sync="teacherVisible" width="700px">
      <el-form
        class="addTeacherForm-box"
        :model="teacherForm"
        ref="teacherForm"
        label-width="100px"
        :rules="teacherFormRules"
      >
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="teacherForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role">
          <el-select
            v-model="teacherForm.role"
            placeholder="请选择角色"
            filterable
            value-key="id"
          >
            <el-option
              v-for="role in roleList"
              :key="role.id"
              :label="role.name"
              :value="{ name: role.name, id: role.id }"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="telephone">
          <el-input v-model="teacherForm.telephone"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="teacherForm.sex">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="职称" prop="title">
          <el-input v-model="teacherForm.title"></el-input>
        </el-form-item>
        <el-form-item label="是否党员" prop="partyMember">
          <el-radio-group v-model="teacherForm.partyMember">
            <el-radio :label="2">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="职级" prop="rank">
          <el-input v-model="teacherForm.rank"></el-input>
        </el-form-item>
        <el-form-item label="所属部门" prop="departmentName">
          <el-input v-model="teacherForm.departmentName" disabled></el-input>
        </el-form-item>
        <el-form-item label="学历" prop="education">
          <el-input v-model="teacherForm.education"></el-input>
        </el-form-item>
        <el-form-item label="毕业院校" prop="graduateSchool">
          <el-input v-model="teacherForm.graduateSchool"></el-input>
        </el-form-item>
        <el-form-item label="所学专业" prop="major">
          <el-input v-model="teacherForm.major"></el-input>
        </el-form-item>
        <el-form-item label="教师资格证" prop="teacherQualificationCertificate">
          <el-select
            v-model="teacherForm.teacherQualificationCertificate"
            placeholder="请选择教师资格证"
          >
            <el-option
              v-for="teacherQualificationCertificate in teacherQualificationCertificateList"
              :key="teacherQualificationCertificate.value"
              :label="teacherQualificationCertificate.name"
              :value="teacherQualificationCertificate.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份" prop="identity">
          <el-select v-model="teacherForm.identity">
            <el-option
              v-for="identity in identityList"
              :key="identity.value"
              :label="identity.name"
              :value="identity.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="teacherForm.email"></el-input>
        </el-form-item>
        <el-form-item label="入职时间" prop="hireTime">
          <el-date-picker
            v-model="teacherForm.hireTime"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="teacherVisible = false">取 消</el-button>
        <el-button type="primary" @click="addConfirm">确 定 </el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="detailsVisible" width="700px">
      <el-tabs type="card">
        <el-tab-pane label="用户信息">
          <el-form
            class="addTeacherForm-box"
            :model="teacherForm"
            ref="modifyTeacherForm"
            label-width="100px"
            :rules="teacherFormRules"
          >
            <el-form-item label="用户名" prop="userName">
              <el-input v-model="teacherForm.userName"></el-input>
            </el-form-item>
            <el-form-item label="角色" prop="role">
              <el-select
                v-model="teacherForm.role"
                placeholder="请选择角色"
                filterable
                value-key="id"
              >
                <el-option
                  v-for="role in roleList"
                  :key="role.id"
                  :label="role.name"
                  :value="{ name: role.name, id: role.id }"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号" prop="telephone">
              <el-input v-model="teacherForm.telephone"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="teacherForm.sex">
                <el-radio label="男"></el-radio>
                <el-radio label="女"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="职称" prop="title">
              <el-input v-model="teacherForm.title"></el-input>
            </el-form-item>
            <el-form-item label="是否党员" prop="partyMember">
              <el-radio-group v-model="teacherForm.partyMember">
                <el-radio :label="2">是</el-radio>
                <el-radio :label="1">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="职级" prop="rank">
              <el-input v-model="teacherForm.rank"></el-input>
            </el-form-item>
            <el-form-item label="所属部门" prop="departmentName">
              <el-input
                v-model="teacherForm.departmentName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="学历" prop="education">
              <el-input v-model="teacherForm.education"></el-input>
            </el-form-item>
            <el-form-item label="毕业院校" prop="graduateSchool">
              <el-input v-model="teacherForm.graduateSchool"></el-input>
            </el-form-item>
            <el-form-item label="所学专业" prop="major">
              <el-input v-model="teacherForm.major"></el-input>
            </el-form-item>
            <el-form-item
              label="教师资格证"
              prop="teacherQualificationCertificate"
            >
              <el-select
                v-model="teacherForm.teacherQualificationCertificate"
                placeholder="请选择教师资格证"
              >
                <el-option
                  v-for="teacherQualificationCertificate in teacherQualificationCertificateList"
                  :key="teacherQualificationCertificate.value"
                  :label="teacherQualificationCertificate.name"
                  :value="teacherQualificationCertificate.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="身份" prop="identity">
              <el-select v-model="teacherForm.identity">
                <el-option
                  v-for="identity in identityList"
                  :key="identity.value"
                  :label="identity.name"
                  :value="identity.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="teacherForm.email"></el-input>
            </el-form-item>
            <el-form-item label="入职时间" prop="hireTime">
              <el-date-picker
                v-model="teacherForm.hireTime"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="是否在职" prop="state">
              <el-switch
                v-model="teacherForm.state"
                active-text="在职"
                inactive-text="离职"
              ></el-switch>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="modifyConfirm" class="modify-button"
            >修 改
          </el-button>
        </el-tab-pane>
        <el-tab-pane label="重置密码">
          <div class="resetPwd-box">
            <el-form
              :model="pwdForm"
              :rules="pwdFormRules"
              ref="pwdForm"
              label-width="80px"
            >
              <el-form-item label="密码" prop="pwd">
                <el-input v-model="pwdForm.pwd" type="password"></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="repeatPwd">
                <el-input
                  v-model="pwdForm.repeatPwd"
                  type="password"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <el-button type="warning" @click="resetPwd" class="modify-button"
            >密码重置
          </el-button>
        </el-tab-pane>
        <el-tab-pane label="删除用户">
          此操作将删除用户及用户所有信息
          <el-button type="danger" class="delete-button" @click="deleteClick"
            >删除用户
          </el-button>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "teacherManage",
  data() {
    return {
      queryTeacherForm: {
        telephone: "",
        name: "",
        state: "在职",
        role: null,
        import: "",
        export: "",
        identity: 1,
      },
      teacherForm: {
        userName: "",
        role: null,
        telephone: "",
        title: "",
        departmentName: "",
        graduateSchool: "",
        rank: "",
        sex: "女",
        hireTime: "",
        major: "",
        partyMember: 1,
        state: false,
        id: "",
        password: "",
        education: "",
        teacherQualificationCertificate: "",
        identity: 1,
        email: "",
      },
      page: { currentPage: 0, pageCount: 1 },
      teacherTable: [],
      teacherQualificationCertificateList: [
        { name: "小学", value: 1 },
        { name: "初级中学", value: 2 },
        { name: "高级中学", value: 3 },
        { name: "中等职业学校", value: 4 },
        { name: "中等职业学校实习指导", value: 5 },
        { name: "其他", value: 6 },
      ],
      stateList: [
        { name: "全部", value: "" },
        { name: "在职", value: "在职" },
        { name: "离职", value: "离职" },
      ],
      identityList: [
        { name: "校内", value: 1 },
        { name: "校外", value: 2 },
      ],
      teacherVisible: false,
      detailsVisible: false,
      pwdForm: {
        pwd: "",
        repeatPwd: "",
      },
      teacherFormRules: {
        userName: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        role: [
          {
            required: true,
            message: "请选择角色",
            trigger: "blur",
          },
        ],
        telephone: [
          {
            required: true,
            message: "请输入电话",
            trigger: "blur",
          },
        ],
        // title: [
        //   {
        //     required: true,
        //     message: "请输入职称",
        //     trigger: "blur",
        //   },
        // ],
        graduateSchool: [
          {
            required: true,
            message: "请输入毕业院校",
            trigger: "blur",
          },
        ],
        // rank: [
        //   {
        //     required: true,
        //     message: "请输入职级",
        //     trigger: "blur",
        //   },
        // ],
        sex: [
          {
            required: true,
            message: "请输入性别",
            trigger: "blur",
          },
        ],
        major: [
          {
            required: true,
            message: "请输入所学专业",
            trigger: "blur",
          },
        ],
        teacherQualificationCertificate: [
          {
            required: true,
            message: "请选择教师资格证",
            trigger: "blur",
          },
        ],
        identity: [
          {
            required: true,
            message: "请选择身份",
            trigger: "blur",
          },
        ],
        education: [
          {
            required: true,
            message: "请填写学历",
            trigger: "blur",
          },
        ],
        partyMember: [
          {
            required: true,
            message: "请填写",
            trigger: "blur",
          },
        ],
      },
      pwdFormRules: {
        pwd: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        repeatPwd: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur",
          },
        ],
      },
      uploadHeader: { Authorization: sessionStorage.getItem("token") },
      roleList: [],
      multipleSelection: [],
    };
  },
  created() {
    this.loadAllTeacherList(1);
    const that = this;
    this.$post("/media/queryallrole.do", { roleName: "" })
      .then((res) => {
        that.roleList = res.data;
      })
      .catch((err) => {
        that.$message.error(err.message);
      });
  },
  methods: {
    loadAllTeacherList(page) {
      const that = this;
      let data = {
        indexNo: page,
        name: this.queryTeacherForm.name,
        is_no: this.queryTeacherForm.identity,
      };
      if (this.queryTeacherForm.role !== null) {
        data.role_id = this.queryTeacherForm.role.id;
      }
      if (this.queryTeacherForm.state !== "") {
        data.state = this.queryTeacherForm.state;
      }
      if (this.queryTeacherForm.telephone !== "") {
        data.mobile = this.queryTeacherForm.telephone;
      }
      this.$post("/media/queryManagerList.do", data)
        .then((res) => {
          that.page.currentPage = page;
          that.page.pageCount = res.data.pageCount;
          that.teacherTable = res.data.rows;
        })
        .catch((error) => {
          that.$message.error(error.message);
        });
    },
    addTeacher() {
      this.teacherVisible = true;
      this.$nextTick(() => {
        this.$refs["teacherForm"].resetFields();
        if (this.$refs["modifyTeacherForm"]) {
          this.$refs["modifyTeacherForm"].resetFields();
        }
        this.teacherForm.role = null;
        this.teacherForm.id = "";
        this.teacherForm.password = "";
      });
    },
    addConfirm() {
      const that = this;
      this.$refs["teacherForm"].validate((valid) => {
        if (valid) {
          // 添加教师接口
          let data = {
            name: this.teacherForm.userName,
            mobile: this.teacherForm.telephone,
            hiretime: this.teacherForm.hireTime,
            sex: this.teacherForm.sex,
            graduate_school: this.teacherForm.graduateSchool,
            major: this.teacherForm.major,
            title: this.teacherForm.title,
            rank: this.teacherForm.rank,
            role_id: this.teacherForm.role.id,
            education: this.teacherForm.education,
            qualifications: this.teacherForm.teacherQualificationCertificate,
            is_no: this.teacherForm.identity,
            email: this.teacherForm.email,
            is_party: this.teacherForm.partyMember,
          };
          let apiUrl = "/media/insertManager.do";
          this.$confirm("是否确认执行此操作", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post(apiUrl, data)
                .then((res) => {
                  that.teacherVisible = false;
                  that.$message({
                    type: "success",
                    message: "操作成功",
                  });
                  that.loadAllTeacherList(1);
                })
                .catch((error) => {
                  that.$message.error(error.message);
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消更改",
              });
            });
        }
      });
    },
    modifyConfirm() {
      const that = this;
      this.$refs["modifyTeacherForm"].validate((valid) => {
        if (valid) {
          let data = {
            name: this.teacherForm.userName,
            mobile: this.teacherForm.telephone,
            hiretime: this.teacherForm.hireTime,
            sex: this.teacherForm.sex,
            graduate_school: this.teacherForm.graduateSchool,
            major: this.teacherForm.major,
            title: this.teacherForm.title,
            rank: this.teacherForm.rank,
            role_id: this.teacherForm.role.id,
            id: this.teacherForm.id,
            state: this.teacherForm.state ? "在职" : "离职",
            education: this.teacherForm.education,
            qualifications: this.teacherForm.teacherQualificationCertificate,
            is_no: this.teacherForm.identity,
            email: this.teacherForm.email,
            is_party: this.teacherForm.partyMember,
          };
          let apiUrl = "/media/updateManager.do";
          this.$confirm("是否修改用户信息", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post(apiUrl, data)
                .then((res) => {
                  that.$message({
                    type: "success",
                    message: "修改成功",
                  });
                  that.detailsVisible = false;
                  that.loadAllTeacherList(1);
                })
                .catch((error) => {
                  that.$message.error(error.message);
                  that.detailsVisible = false;
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消修改",
              });
            });
        }
      });
    },
    details(currentList) {
      this.detailsVisible = true;
      this.$nextTick(() => {
        this.teacherForm.userName = currentList.name;
        this.teacherForm.role = {
          id: currentList.role_id,
          name: currentList.rolename,
        };
        this.teacherForm.telephone = currentList.mobile;
        this.teacherForm.title = currentList.title;
        this.teacherForm.departmentName = currentList.group_name;
        this.teacherForm.graduateSchool = currentList.graduate_school;
        this.teacherForm.rank = currentList.rank;
        this.teacherForm.sex = currentList.sex;
        this.teacherForm.hireTime = currentList.hiretime;
        this.teacherForm.major = currentList.major;
        this.teacherForm.state = currentList.state === "在职";
        this.teacherForm.id = currentList.id;
        this.teacherForm.password = currentList.password;
        this.teacherForm.education = currentList.education;
        this.teacherForm.teacherQualificationCertificate =
          currentList.qualifications;
        this.teacherForm.identity = currentList.is_no;
        this.teacherForm.email = currentList.email;
      });
    },
    uploadSuccess(response, file, fileList) {
      if (response.type === 1) {
        this.$message({
          type: "success",
          message: "已上传文件",
        });
        this.loadAllTeacherList(1);
      } else {
        this.$message({
          type: "warning",
          message: response.message,
        });
      }
    },
    exportFile(type) {
      const that = this;
      let data
      let url
      let name
      let type1
      if (type === 1) {
        url = `/media/exportTeaMes.do`
        name = '教师导出'
        type1 = `application/vnd.ms-excel`
        data = {
          name: this.teacherForm.userName,
          state: this.teacherForm.state ? "在职" : "离职",
          mobile: this.teacherForm.telephone,
          is_no: this.teacherForm.identity,
        };
        if (this.queryTeacherForm.role !== null) {
          data.role_id = this.queryTeacherForm.role.id;
        }
      }else{
        url = `/media/exportTeaPhoto.do`
        name = '教师头像导出'
        type1 = `application/zip`
        data = {}
      }

      this.$postFile(url, data)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: type1,
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = name;
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    downLoadTeacherTemplate() {
      const that = this;
      this.$postFile("/media/downloadteamestemplate.do", {})
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "教师导入模板";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    resetPwd() {
      const that = this;
      if (this.pwdForm.pwd === this.pwdForm.repeatPwd) {
        this.$confirm("是否重置密码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$post("/media/updateManager.do", {
              id: this.teacherForm.id,
              password: this.pwdForm.pwd,
            })
              .then((res) => {
                that.$message({
                  type: "success",
                  message: "重置成功!",
                });
                that.detailsVisible = false;
              })
              .catch((error) => {
                that.$message.error(error.message);
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消重置",
            });
          });
      } else {
        this.$message({
          type: "warning",
          message: "密码与原密码一致",
        });
      }
    },
    resetAllTeacher() {
      this.$prompt("请输入初始密码", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.$confirm("是否重置全校教师密码", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post("/media/updateAllManagerPassword.do", {
                password: value,
              })
                .then((res) => {
                  this.$message({
                    type: "success",
                    message: "重置成功!",
                  });
                  this.detailsVisible = false;
                })
                .catch((error) => {
                  this.$message.error(error.message);
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消重置",
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    deleteClick() {
      const that = this;
      this.$confirm("是否删除该用户", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/deleteManager.do", {
            id: this.teacherForm.id,
          })
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.detailsVisible = false;
              that.loadAllTeacherList(1);
            })
            .catch((error) => {
              that.$message({
                type: "warning",
                message: error.message,
              });
              that.detailsVisible = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSelectionChange(event) {
      this.multipleSelection = [];
      event.forEach((row) => {
        this.multipleSelection.push(row.id);
      });
    },
    batchDelete() {
      let idList = this.multipleSelection.toString();
      this.$confirm("是否确认批量删除数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/deleteManagerIds.do", { ids: idList })
            .then((res) => {
              this.loadAllTeacherList(1);
              this.$message({ type: "success", message: res.message });
            })
            .catch((err) => {
              this.$message({ type: "warning", message: err.message });
            });
        })
        .catch(() => {
          this.$message({ type: "warning", message: "已取消删除" });
        });
    },
  },
};
</script>

<style scoped lang="scss">
// /deep/ .el-input {
//   width: 100%;
// }
.flex-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.button-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 670px;
}
// .box-width {
//   width: 190px;
// }
.addTeacherForm-box {
  display: flex;
  flex-wrap: wrap;
}
.modify-button {
  margin-left: 535px;
}
.delete-button {
  margin: 20px 0 0 535px;
}
.resetPwd-box {
  width: 350px;
  margin: 0 auto;
}
.form-box {
  width: 890px;
}
</style>
